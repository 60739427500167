export default {
  firstHeading: 'הליך מימון',
  firstText: [
    'באולטרה פיננסים מאמינים ביעילות.',
    'הליך קבלת המימון מאיתנו קצר וחסכוני.',
    'התוצאה מדויקת. לא מבזבזים לך את הזמן',
    'על פגישות מיותרות או את הכסף על ״יועצים״,',
    'שיכתבו לך תוכנית עסקית. וכן, אנחנו באמת',
    'עושים את זה תוך יומיים.',
  ],
  gridHeading: 'שלבי המימון',
  gridItemOneHeading: 'הגשת בקשה און ליין',
  gridItemOneText:
    'יש לך פקס? יופי, גם לנו אין. אנחנו גם לא צריכים שתבוא לסניף. תגיש בקשה באתר.  תעלו פה את החומרים ותנו לנו ללמוד את העסק שלכם. לאחר מכן נדבר בטלפון כדי להבין יותר את הצרכים שלכם. אם נחליט ביחד שמתאים שנהיה בנקאי המימון שלך נקבע פגישה אצלך בעסק.',
  gridItemTwoHeading: 'ביקור בעסק',
  gridItemTwoText:
    'היית קונה דירה או עסק בלי לבדוק אותו? גם אנחנו לא. בנקאי מימון מגיע להתרשם מהעסק ולבנות ביחד איתך את תוכנית המימון. דיגיטל זה אחלה אבל מי שמתאים לנו לעבוד איתו זה רק מישהו שפגשנו ודיברנו איתו. אבל כן, אם יש לך אתר שמוכר און ליין גם שיחת וידאו יכולה לעשות את העבודה. אנחנו לא מקובעים.',
  gridItemThreeHeading: 'חיתום ובניית תוכנית מימון',
  gridItemThreeText:
    'בנקאי מימון בוחן את כל נתוני העסק, שואל שאלות נוספות אם צריך ומאמת את הנתונים עם רואה החשבון של העסק וגורמים נוספים. החיתום שלנו מבוסס על מערכת מתוחכמת שמאפשרת לבנקאי ליצור כל מוצר אשראי ולא מקובעת להלוואות שפיצר/בלון/בוליט. בנוסף הריבית שתשלם מותאמת לעסק שלך אישית. אתה לא צריך לממן עסקים מסוכנים יותר ועסקים מבוססים יותר לא צריכים לממן אותך.',
  gridItemFourHeading: 'קבלת המימון ותשלום חודשי אוטומטי',
  gridItemFourText:
    'הזרמת התשלום לחשבון הבנק של העסק וגבייה אוטומטית חודשית של תשלומי הלוואה באמצעות הרשאה לחיוב חשבון. מעבר לכך, אנחנו שומרים על קשר הדוק עם העסקים שמקבלים מאיתנו שירותי בנקאות מימון. בין אם אנחנו יכולים לעזור בעצה, חיבור עסקי או שינוי מבנה המימון בהתאם לצרכי העסק.',
};
