import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import Axios from 'axios';

import { sendGetInTouchMessage } from '../../../lib/getInTouchMessageHandler';
import * as styles from './contactSection.module.css';
import clientConfig from '../../../../client-config';
import hebrewTexts from '../commonHebrewTexts';
type ContactProps = {
  link: string;
};
const ContactSection = ({ link }: ContactProps) => {
  const { register, handleSubmit } = useForm<{ name: string; mail: string; content: string }>();
  const [show, setShow] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  const onSubmit = async (data: { name: string; mail: string; content: string }) => {
    if (data.name != '' && data.mail != '' && data.content != '') {
      void sendGetInTouchMessage(data.name, data.mail, data.content)
        .then(() => {
          setShowOther(!showOther);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setShow(!show);
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleCloseOther = () => {
    setShowOther(false);
  };

  let getModals = () => (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right' id='alertText'>
            אנא מלא את כל השדות
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {hebrewTexts.close}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showOther} onHide={handleCloseOther}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right' id='alertText'>
            תודה על הפנייה, כבר נחזור אלייך
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseOther}>
            {hebrewTexts.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  return (
    <>
      {getModals()}
      <div className={styles.contactUsWrap} dir='ltr'>
        <div className={styles.contactUsForm}>
          <h3>דברו איתנו</h3>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId='formBasicName'>
              <Form.Control
                className={styles.contactName}
                type='text'
                name='name'
                dir='rtl'
                ref={register}
                placeholder='שם מלא'
              />
            </Form.Group>
            <Form.Group controlId='formBasicEmail'>
              <Form.Control
                className={styles.contactEmail}
                type='email'
                name='mail'
                dir='rtl'
                ref={register}
                placeholder='אימייל'
              />
            </Form.Group>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
              <Form.Control
                className={styles.contactReferal}
                as='textarea'
                name='content'
                placeholder='תוכן הפנייה'
                dir='rtl'
                ref={register}
                rows={5}
              />
            </Form.Group>
            <Button type='submit' className={styles.contactUsButton}>
              שליחה
            </Button>
          </Form>
        </div>
        <div className={styles.contactUsImgWrap}>
          <img src={link} />
          <h3 className={styles.contactUsText}>אנחנו עוסקים במתן מימון יעיל לעסקים</h3>
          <h4 className={styles.contactUsQuote}>״</h4>
        </div>
      </div>
    </>
  );
};
export default ContactSection;
