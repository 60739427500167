import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styles from './financeProcess2.module.css';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import TEXTS from './hebrewTexts';
import SvgIcons from '../CompanyPage/SvgIcons';
import ContactSection from '../ContactSection/contactSession';
type AppProps = {
  labelArr: Array<object>;
};
const FinanceProcess = ({ labelArr }: AppProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.coverBlock}>
        <img src='/assets/images/finance-cover-image.jpg' />
        <div className={styles.coverBlockText}>
          <h3>{TEXTS.firstHeading}</h3>
          {TEXTS.firstText.map((item) => (
            <p dir='rtl'>{item}</p>
          ))}
        </div>
      </div>
      <h3 className={styles.gridHeading}>{TEXTS.gridHeading}</h3>
      <div className={styles.gridWrap}>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemOneHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemOneText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>
            {SvgIcons.files}
            <span>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemTwoHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemTwoText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>
            {SvgIcons.coffee}
            <span>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemThreeHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemThreeText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>
            {SvgIcons.pencil}
            <span>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _</span>
          </div>
        </div>
        <div className={styles.gridItem}>
          <div className={styles.gridItemTextWrap}>
            <h3>{TEXTS.gridItemFourHeading}</h3>
            <p dir='rtl'>{TEXTS.gridItemFourText}</p>
          </div>
          <div className={styles.gridItemSvgWrap}>{SvgIcons.shekel}</div>
        </div>
      </div>
      <ContactSection link='/assets/images/finance_contact_image.png' />
    </div>
  );
};

export default FinanceProcess;
