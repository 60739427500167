import axios from 'axios';
import clientConfig from '../../client-config';

export function sendGetInTouchMessage(name: string, mail: string, content: string): Promise<void> {
  return axios.post(`${clientConfig.backend}/api/v1/lastRegistrationPage/getInTouch`, {
    name,
    mail,
    content,
  });
}
